import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { CloudinaryService } from '../../../core/services/cloudinary.service';
import { BlogItem } from '../../../core/types/blogs';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'blog-item',
  standalone: true,
  imports: [DatePipe, CommonModule, RouterLink, MatTooltipModule],
  templateUrl: './blog-item.component.html',
  styleUrl: './blog-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogItemComponent {
  @Input() blog: BlogItem;
  @Input() tags: Record<number, string> = {};
  constructor(
    public cloudinary: CloudinaryService,
    private router: Router,
  ) {}

  viewDetails() {
    this.router.navigate([`blogs/details/${this.blog.link}`], {
      replaceUrl: false,
    });
  }
}
