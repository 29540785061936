@if (blog) {
  <a [routerLink]="blog.link">
    <div class="flex flex-col cursor-pointer">
      <img
        [src]="cloudinary.getUrl({ id: blog.media, width: 1000 })"
        class="w-full aspect-[1.576] object-cover object-center rounded-lg bg-slate-100"
      />

      <div class="flex flex-row flex-wrap gap-4 items-center mt-8 mb-2">
        @for (id of blog.tags; track $index) {
          <span class="bg-primaryBg px-5 py-2 max-w-max rounded-md">
            <h3 class="text-[#666666] font-medium text-12">{{ tags[id] }}</h3>
          </span>
        }
      </div>

      <h3 class="text-black font-medium text-18 md:text-22 leading-[28px] line-clamp-1" [matTooltip]="blog.title">
        {{ blog.title }}
      </h3>

      <div class="inline-flex items-center gap-5 pt-8 pb-2">
        <h3 class="text-[#777777] flex-shrink-0 text-12">{{ blog.readTime }} Min Read</h3>
        <h3 class="text-[#777777] text-12 font-light">|</h3>
        <h3 class="text-[#777777] flex-shrink-0 text-12">{{ blog.createOn | date: "d MMM y" }}</h3>
        <h3 class="text-[#777777] text-12 font-light">|</h3>
        <h3 class="text-[#777777] text-12 line-clamp-1">{{ blog.authorName }}</h3>
      </div>

      <h4 class="text-14 leading-[22px] text-[#555555] line-clamp-3">
        {{ blog.description }}
      </h4>
    </div>
  </a>
} @else {
  <div class="flex flex-col">
    <div class="w-full aspect-[1.576] shimmer rounded-lg"></div>
    <div class="w-full h-10 my-5 shimmer rounded-lg"></div>

    <div class="flex flex-col gap-2">
      @for (_ of [1, 2, 3, 4]; track $index) {
        <div [ngClass]="$index == 3 ? 'w-5/6' : 'w-full'" class="h-6 shimmer rounded-lg"></div>
      }
    </div>
  </div>
}
